import React from "react"
import Layout from "../../components/member/Layout"
import profile from "../../components/common/Profile"

export default props => {
  const Profile = profile("profile")
  return (
    <div>
      <Layout>
        <Profile />
      </Layout>
    </div>
  )
}
